import { NgModule } from "@angular/core";

import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { TableModule } from "primeng/table";
import { MultiSelectModule } from "primeng/multiselect";
import { CalendarModule } from "primeng/calendar";
import { FileUploadModule } from "primeng/fileupload";
import { RadioButtonModule } from "primeng/radiobutton";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ProgressBarModule } from "primeng/progressbar";
import { GrowlModule } from "primeng/growl";
import { TabViewModule } from "primeng/tabview";
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToolbarModule } from 'primeng/toolbar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { OrderListModule } from 'primeng/orderlist';
import {MessageModule} from 'primeng/message';
import {DialogModule} from 'primeng/dialog';
import {ScheduleModule} from 'primeng/schedule';
import {BlockUIModule} from 'primeng/blockui';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {AccordionModule} from 'primeng/accordion';
import {SlideMenuModule} from 'primeng/slidemenu';
import {CarouselModule} from 'primeng/carousel';
import {SidebarModule} from 'primeng/sidebar';
import {FieldsetModule} from 'primeng/fieldset';
import { NgxGalleryModule } from 'ngx-gallery';


import { ValidationTooltipDirective } from "./validationTooltip.directive";
import { GreaterThanValidator } from "./greaterThanValidator.directive";
import { GreaterThanDateValidator } from "./greaterThanDateValidator.directive";
import { EllipsisPipe } from './ellipsis.pipe';
import { AuthGuard } from "./auth.guard";
import { LoggedInGuard } from "./loggedIn.guard";
import { CanAccessDirective } from "./canAccess.directive"; 
import { ClickOutsideDirective } from "./clickOutside.directive";



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxGalleryModule,
        TableModule,
        MultiSelectModule,
        ButtonModule,
        CalendarModule,
        DropdownModule,
        FileUploadModule,
        RadioButtonModule,
        CheckboxModule,
        InputTextModule,
        CardModule,
        ProgressSpinnerModule,
        ProgressBarModule,
        GrowlModule,
        TabViewModule,
        OverlayPanelModule,
        TooltipModule,
        SplitButtonModule,
        InputSwitchModule,
        ToolbarModule,
        BreadcrumbModule,
        InputTextareaModule,
        OrderListModule,
        MessageModule,
        DialogModule,
        ScheduleModule,
        BlockUIModule,
        AccordionModule,
        SlideMenuModule,
        CarouselModule,
        SidebarModule,
        FieldsetModule,
        ConfirmDialogModule,
    ],
    declarations: [
        ValidationTooltipDirective,
        CanAccessDirective,
        ClickOutsideDirective,
        GreaterThanValidator,
        GreaterThanDateValidator,
        EllipsisPipe
    ],
    providers: [ AuthGuard,LoggedInGuard],
    exports: [
        FormsModule,
        ValidationTooltipDirective,
        CanAccessDirective,
        ClickOutsideDirective,
        EllipsisPipe,
        GreaterThanValidator,
        GreaterThanDateValidator,
        NgxGalleryModule,
        TableModule,
        MultiSelectModule,
        ButtonModule,
        CalendarModule,
        DropdownModule,
        FileUploadModule,
        RadioButtonModule,
        CheckboxModule,
        InputTextModule,
        CardModule,
        ProgressSpinnerModule,
        ProgressBarModule,
        GrowlModule,
        TabViewModule,
        OverlayPanelModule,
        TooltipModule,
        SplitButtonModule,
        InputSwitchModule,
        ToolbarModule,
        BreadcrumbModule,
        InputTextareaModule,
        OrderListModule,
        MessageModule,
        DialogModule,
        ScheduleModule,
        BlockUIModule,
        AccordionModule,
        SlideMenuModule,
        CarouselModule,
        SidebarModule,
        FieldsetModule,
        ConfirmDialogModule,
    ]
})

export class SharedModule{}
