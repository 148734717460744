import { Injectable, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { Room } from 'src/app/models/Room';
import * as moment from 'moment';


interface TransferDetails{
    transfer: number,
    orders : {
        transferDate : string,
        pax:  number,
        isFastTrack: boolean,
        quantity: number
    }[]
}
interface HotelDetails{
    room: number,
    orders : {
        checkIn: string,
        checkOut: string,
        nights: number,
        single: number,
        double: number,
    }[]
}

@Injectable()

export class CartService{

    @Output() itemAdded = new EventEmitter<number>();

    constructor(
        private _http : HttpClient,
        @Inject(SESSION_STORAGE) private _storage: StorageService,
        private _notificationsService: NotificationsService
    ){}

    addTransferItem(transfer, transferDate:string,pax: number, isFastTrack:boolean){
        let cart: any[] = this._storage.get('transfers-cart')? this._storage.get('transfers-cart') : [];
        let details:TransferDetails[] = this._storage.get('transfers-details') ? this._storage.get('transfers-details') : [];
        const transferIndex = cart.indexOf(transfer.id);
        if(transferIndex === -1){
            cart.push(transfer.id);
            details.push({
                transfer : transfer.id,
                orders: [{
                    transferDate : transferDate,
                    pax : pax,
                    isFastTrack : isFastTrack,
                    quantity : 1
                }]
            });
        }else{
            const orders = details[transferIndex].orders.filter(function(order){
               return  order.transferDate === transferDate &&
                        order.pax === pax &&
                        order.isFastTrack === isFastTrack;
            });
            if(orders.length === 0){
                details[transferIndex].orders.push({
                    transferDate: transferDate,
                    pax : pax,
                    isFastTrack : isFastTrack,
                    quantity : 1
                });
            }else{
                const orderIndex = details[transferIndex].orders.indexOf(orders[0]);
                details[transferIndex].orders[orderIndex].quantity++;
            }

        }
        this._storage.set('transfers-cart',cart);
        this._storage.set('transfers-details',details);
        this.itemAdded.emit(this.getCartCount());
        this._notificationsService.notify(
            'success',
            'Cart updated!',
            'Item successfully added to cart!'
        )
    }

    addRoomItem(room: Room, checkIn: string, checkOut: string, type:string){
        let cart: any[] = this._storage.get('rooms-cart')? this._storage.get('rooms-cart') : [];
        let details:HotelDetails[] = this._storage.get('rooms-details') ? this._storage.get('rooms-details') : [];
        const roomIndex = cart.indexOf(room.id);
        if(roomIndex === -1){
            cart.push(room.id);
            details.push({
                room : room.id,
                orders: [{
                    checkIn: checkIn,
                    checkOut: checkOut,
                    nights: moment(checkOut,'YYYY-MM-DD',true).get('date') - moment(checkIn,'YYYY-MM-DD',true).get('date'),
                    single: type === 'single'? 1 : 0,
                    double: type === 'double'? 1 : 0,
                }]
            });
        }else{
            const orders = details[roomIndex].orders.filter(function(order){
                return  order.checkIn === checkIn && order.checkOut === checkOut;
            });
            if(orders.length === 0){
                details[roomIndex].orders.push({
                    checkIn : checkIn,
                    checkOut: checkOut,
                    nights: moment(checkOut,'YYYY-MM-DD',true).get('date') - moment(checkIn,'YYYY-MM-DD',true).get('date'),
                    single : type === 'single' ? 1: 0,
                    double : type === 'double' ? 1: 0
                });
            }else{
                const orderIndex = details[roomIndex].orders.indexOf(orders[0]);
                if(type === 'single'){
                    details[roomIndex].orders[orderIndex].single++;
                }else{
                    details[roomIndex].orders[orderIndex].double++;
                }
            }

        }
        this._storage.set('rooms-cart',cart);
        this._storage.set('rooms-details',details);
        this.itemAdded.emit(this.getCartCount());
        this._notificationsService.notify(
            'success',
            'Cart updated!',
            'Item successfully added to cart!'
        )
    }


    getCartCount(){
        let transferDetails:TransferDetails[] = this._storage.get('transfers-details') ? this._storage.get('transfers-details') : [];
        let transferCount =  transferDetails.reduce((count,item) =>{
            count = count + item.orders.reduce((transferCount, order) =>{
                transferCount = transferCount + order.quantity;
                return transferCount;
            },0);
            return count
        },0);
        let hotelDetails:HotelDetails[] = this._storage.get('rooms-details') ? this._storage.get('rooms-details') : [];
        let hotelCount = hotelDetails.reduce((count,item) =>{
                    count = count + item.orders.reduce((roomCount, order) =>{
                        roomCount = roomCount + order.single + order.double ;
                        return roomCount;
                    },0);
                    return count;
                    },0);
        return transferCount + hotelCount;
    }

    reduceTransferQuantity(id:number, transferDate:string, pax: number, isFastTrack: boolean){
        let cart: any[] = this._storage.get('transfers-cart')? this._storage.get('transfers-cart') : [];
        let details:TransferDetails[] = this._storage.get('transfers-details') ? this._storage.get('transfers-details') : [];
        const transferIndex = cart.indexOf(id);
        if(transferIndex !== -1){
            const orders = details[transferIndex].orders.filter(function(order){
                return  order.transferDate === transferDate &&
                        order.pax === pax &&
                        order.isFastTrack === isFastTrack;
            });
            if(orders.length === 1){
                const orderIndex = details[transferIndex].orders.indexOf(orders[0]);
                details[transferIndex].orders[orderIndex].quantity--;
                // No orders left
                if(details[transferIndex].orders[orderIndex].quantity === 0 ){
                    details[transferIndex].orders.splice(orderIndex,1);
                    if(details[transferIndex].orders.length === 0){
                        cart.splice(transferIndex,1);
                        details.splice(transferIndex,1);
                    }
                }

                this._storage.set('transfers-cart',cart);
                this._storage.set('transfers-details',details);
                this.itemAdded.emit(this.getCartCount());
                this._notificationsService.notify(
                    'success',
                    'Cart updated!',
                    'Item successfully removed from cart!'
                )
            }else{
                this._notificationsService.notify(
                    'error',
                    'Error!',
                    'Can\'t find a matching item!'
                )
            }
        }else{
            this._notificationsService.notify(
                'error',
                'Error!',
                'Item not found in cart!',
            )
        }
    }

    reduceRoomQuantity(room_id:number, checkIn:string, checkOut:string,type:string){
        let cart: any[] = this._storage.get('rooms-cart')? this._storage.get('rooms-cart') : [];
        let details:HotelDetails[] = this._storage.get('rooms-details') ? this._storage.get('rooms-details') : [];

        const roomIndex = cart.indexOf(room_id);
        if(roomIndex !== -1){
            const orders = details[roomIndex].orders.filter((order) => {
                return  order.checkIn === checkIn && order.checkOut === checkOut;
            });
            if(orders.length === 1){
                const orderIndex = details[roomIndex].orders.indexOf(orders[0]);
                // Reduce order quantity of the single room
                if(type === 'single' && details[roomIndex].orders[orderIndex].single >= 1){
                    details[roomIndex].orders[orderIndex].single--;
                }
                // Reduce order quantity of the double room
                if(type === 'double' && details[roomIndex].orders[orderIndex].double >= 1){
                    details[roomIndex].orders[orderIndex].double--;
                }
                // No orders left
                if(
                    details[roomIndex].orders[orderIndex].single === 0 &&
                    details[roomIndex].orders[orderIndex].double === 0
                ){
                    details[roomIndex].orders.splice(orderIndex,1);
                    if(details[roomIndex].orders.length === 0){
                        cart.splice(roomIndex,1);
                        details.splice(roomIndex,1);
                    }
                }

                this._storage.set('rooms-cart',cart);
                this._storage.set('rooms-details',details);
                this.itemAdded.emit(this.getCartCount());
                this._notificationsService.notify(
                    'success',
                    'Cart updated!',
                    'Item successfully removed from cart!'
                )
            }else{
                this._notificationsService.notify(
                    'error',
                    'Error!',
                    'Can\'t find a matching item!'
                )
            }
        }else{
            this._notificationsService.notify(
                'error',
                'Error!',
                'Item not found in cart!',
            )
        }
    }

    removeTransferItem(id:number,transferDate:string, pax: number, isFastTrack: boolean){
        let cart: any[] = this._storage.get('transfers-cart')? this._storage.get('transfers-cart') : [];
        let details:TransferDetails[] = this._storage.get('transfers-details') ? this._storage.get('transfers-details') : [];
        const transferIndex = cart.indexOf(id);
        if(transferIndex !== -1){
            const orderIndex = details[transferIndex].orders.findIndex((order) => {
                return  order.transferDate === transferDate &&
                        order.pax === pax &&
                        order.isFastTrack === isFastTrack;
            });
            if(orderIndex !== -1){
                details[transferIndex].orders.splice(orderIndex,1);
                if(details[transferIndex].orders.length === 0){
                    cart.splice(transferIndex,1);
                    details.splice(transferIndex,1);
                }

                this._storage.set('transfers-cart',cart);
                this._storage.set('transfers-details',details);
                this.itemAdded.emit(this.getCartCount());
                this._notificationsService.notify(
                    'success',
                    'Cart updated!',
                    'Item successfully removed from cart!'
                )
            }else{
                this._notificationsService.notify(
                    'error',
                    'Error!',
                    'Can\'t find a matching item!'
                )
            }
        }else{
            this._notificationsService.notify(
                'error',
                'Error!',
                'Item not found in cart!',
            )
        }
    }

    removeRoomItem(room_id:number,checkIn: string, checkOut: string, type: string){
        let cart: any[] = this._storage.get('rooms-cart')? this._storage.get('rooms-cart') : [];
        let details:HotelDetails[] = this._storage.get('rooms-details') ? this._storage.get('rooms-details') : [];
        const roomIndex = cart.indexOf(room_id);
        if(roomIndex !== -1){
            const orderIndex = details[roomIndex].orders.findIndex((order) => {
                return order.checkIn === checkIn && order.checkOut === checkOut;
            });
            if(orderIndex !== -1){
                switch(type){
                    case 'single' :
                        if(details[roomIndex].orders[orderIndex].double > 0 ){
                            details[roomIndex].orders[orderIndex].single = 0;
                        }else{
                            details[roomIndex].orders.splice(orderIndex,1);
                        }
                        break;
                    case 'double':
                        if(details[roomIndex].orders[orderIndex].single > 0 ){
                            details[roomIndex].orders[orderIndex].double = 0;
                        }else{
                            details[roomIndex].orders.splice(orderIndex,1);
                        }
                        break;
                }
                if(details[roomIndex].orders.length === 0){
                    cart.splice(roomIndex,1);
                    details.splice(roomIndex,1);
                }

                this._storage.set('rooms-cart',cart);
                this._storage.set('rooms-details',details);
                this.itemAdded.emit(this.getCartCount());
                this._notificationsService.notify(
                    'success',
                    'Cart updated!',
                    'Item successfully removed from cart!'
                )
            }else{
                this._notificationsService.notify(
                    'error',
                    'Error!',
                    'Can\'t find a matching item!'
                )
            }
        }else{
            this._notificationsService.notify(
                'error',
                'Error!',
                'Item not found in cart!',
            )
        }
    }

    prePayment(transfersData,roomsData,client,total,isdonation){
        return this._http.post<any>(`${environment.apiUrl}/pre-pay`,{
            transfers : transfersData,
            rooms: roomsData,
            client: client,
            total : total,
            isdonation : isdonation,
        });
    }

    postPayment(order){
        return this._http.post<any>(`${environment.apiUrl}/pre-pay`,{
            order : order
        });
    }


    getTransferItemCount(transfer_id: number){
        let details:TransferDetails[] = this._storage.get('transfers-details') ? this._storage.get('transfers-details') : [];
        return details.filter(item => item.transfer === transfer_id)
                      .reduce((count,item) =>{
                        count = count + item.orders.reduce((transferCount, order) =>{
                            transferCount = transferCount + order.quantity;
                            return transferCount;
                        },0);
                        return count;
                      },0);
    }

    getRoomItemCount(room_id: number,type: string){
        let details:HotelDetails[] = this._storage.get('rooms-details') ? this._storage.get('rooms-details') : [];
        return details.filter(item => item.room === room_id)
                      .reduce((count,item) =>{
                        count = count + item.orders.reduce((roomCount, order) =>{
                            roomCount = roomCount + (type === 'single' ? order.single: order.double) ;
                            return roomCount;
                        },0);
                        return count;
                      },0);
    }

    getTransferItemOrders(transfer_id: number){
        let details:TransferDetails[] = this._storage.get('transfers-details') ? this._storage.get('transfers-details') : [];
        details = details.filter(item => item.transfer === transfer_id);
        if(details.length > 0){
            return details[0].orders;
        }
        return [];
    }

    getRoomItemOrders(room_id: number){
        let details:HotelDetails[] = this._storage.get('rooms-details') ? this._storage.get('rooms-details') : [];
        details = details.filter(item => item.room === room_id);
        if(details.length > 0){
            return details[0].orders;
        }
        return [];
    }


}
