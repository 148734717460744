import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import * as moment from 'moment';
import { defaultThrottleConfig } from 'rxjs/internal/operators/throttle';
import { AppConfig } from './models/AppConfig';

@Injectable()

export class AppService{
    /* defaultConfig : { year: number, start_date: Date, end_date: Date, cmi_store_key: string, email: string} = {
        year: 2020,
        start_date: moment('2020-09-04','YYYY-MM-DD',true).toDate(),
        end_date: moment('2020-09-14','YYYY-MM-DD',true).toDate(),
        cmi_store_key: null,
        email: null
    }; */
    appConfig: AppConfig = new AppConfig();
    @Output() appConfigRefreshed = new EventEmitter<any>();

    constructor(
        private _http : HttpClient
    ){}


    getAppConfig(){
        return this._http.get<any>(`${environment.apiUrl}/config`);
    }

    refreshAppConfig(){
        this.appConfig = new AppConfig();
        this.getAppConfig().subscribe((data) => {
            this.appConfig = data.data;
            this.appConfigRefreshed.emit();
        });
    }

    saveAppConfig(formData){
        return this._http.post<any>(`${environment.apiUrl}/config/save`,formData);
    }

}