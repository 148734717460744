import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { NotificationsService } from './notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  
  constructor(
    private _authService : AuthService,
    private _router : Router,
    private _notificationsService: NotificationsService
  ){}

  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
    let permissions = next.data["permissions"] as Array<string>;
    
    if(this._authService.loggedIn()){
      const state = this._authService.checkAuthorization(permissions);
      if(!state){
        this._notificationsService.notify('error','Unauthorized','You\'re not authorized to access this page !');
      }
      return state;
    }else{
      this._authService.redirectUrl = state.url;
      this._router.navigate(['/auth/login']);
      return false;
    }
  }

  canActivateChild(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let permissions = next.data["permissions"] as Array<string>;
    
    if(this._authService.loggedIn()){
      
      const state = this._authService.checkAuthorization(permissions);
      if(!state){
        this._notificationsService.notify('error','Unauthorized','You\'re not authorized to access this page !');
      }
      return state;
    }else{
      this._authService.redirectUrl = state.url;
      this._router.navigate(['/auth/login']);
      return false;
    }
  }
}
