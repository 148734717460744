import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {path : '', loadChildren: () => import('./front-end/front-end.module').then(m => m.FrontEndModule)},
  {path : 'admin', loadChildren: () => import('./back-end/back-end.module').then(m => m.BackEndModule)},
  {path : 'auth', loadChildren : () => import('./auth/auth.module').then(m => m.AuthModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
