import { Component, OnInit } from '@angular/core';
import { CartService } from '../front-end/cart/cart.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  cartCount: number;
  isOpen:boolean = false;

  constructor(
    private _cartService : CartService,
    private _authService : AuthService,
    private _router : Router
  ) { }

  ngOnInit() {
    this.cartCount = this._cartService.getCartCount();

    this._cartService.itemAdded.subscribe((data) => {
      this.cartCount = data;
    });
  }

  loggedIn(){ return this._authService.loggedIn();}

  logOut(){ this._authService.logOut();}

  getUserName(){
    return this._authService.getUserName();
  }
}
