import { Directive, ElementRef, OnInit } from "@angular/core";
import { NgControl } from "@angular/forms";
import { Tooltip } from "primeng/tooltip";

@Directive({
    selector: '[validationTooltip]'
})

export class ValidationTooltipDirective implements OnInit{
    constructor(private el: ElementRef,private control: NgControl,private tooltip: Tooltip) {}

    ngOnInit(){
        if(!this.control.dirty){
            this.tooltip.disabled = true;
        }else{
            if(this.control.valid) {
                this.tooltip.disabled = true;
            }else{
                this.tooltip.text = this.getErrorMessage();
                this.tooltip.disabled = false;
            }
        }
        this.control.statusChanges.subscribe((status) => {
            if(!this.control.dirty){
                this.tooltip.disabled = true;
            }else{
                if(this.control.valid) {
                    this.tooltip.disabled = true;
                }else{
                    this.tooltip.text = this.getErrorMessage();
                    this.tooltip.disabled = false;
                }
            }
        });
    }

    getErrorMessage(){
        const name = this.el.nativeElement.getAttribute('data-name');
        if(!this.control.disabled){
          if(this.control.errors.hasOwnProperty('required')){
              return `${name} is required.`
          }
          if(this.control.errors.hasOwnProperty('minlength')){
              const length = this.control.errors.minlength.requiredLength;
              return `${name} needs to be at least ${length} characters.`
          }
          if(this.control.errors.hasOwnProperty('greaterThan')){
              const target = this.el.nativeElement.getAttribute('data-target-name');
              return `${name} needs to be greater than ${target}.`
          }
          if(this.control.errors.hasOwnProperty('greaterThanDate')){
              const target = this.el.nativeElement.getAttribute('data-target-name');
              return `${name} needs to be greater than ${target}.`
          }
          if(this.control.errors.hasOwnProperty('email')){
              return `${name} needs to be a valid email address.`
          }
        }

    }
}
