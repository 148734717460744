// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl : 'https://api.travellinkmorocco.com/v1',
  baseUrl : 'https://api.travellinkmorocco.com',
  authUrl : 'https://api.travellinkmorocco.com/v1/oauth',
  // apiUrl: 'http://127.0.0.1:8000/v1',
  // baseUrl: 'http://127.0.0.1:8000',
  // authUrl: 'http://127.0.0.1:8000/v1/oauth',
  cmiTestUrl: 'https://testpayment.cmi.co.ma/fim/est3Dgate',
  cmiUrl: 'https://payment.cmi.co.ma/fim/est3Dgate',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
