import { Directive, Input,OnChanges } from '@angular/core';
import {NG_VALIDATORS,Validator, AbstractControl} from '@angular/forms';
import * as moment from 'moment';


@Directive({
    selector: '[greaterThanDate]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: GreaterThanDateValidator,
        multi: true
    }]
})
export class GreaterThanDateValidator implements Validator ,OnChanges{
    @Input('otherControl') otherControl: AbstractControl;

    validate(control: AbstractControl): { [key: string]: any; } {
        if(!control.value || !this.otherControl.value){
            return null;
        }
        return moment(control.value).isSameOrAfter(this.otherControl.value)? null : {'greaterThanDate' : true};
    }

    ngOnChanges(){
        this.otherControl.valueChanges.subscribe(() =>{
            if(this._onChange) this._onChange();
        });
    }
    
    private _onChange: () => void;

    registerOnValidatorChange(fn: () => void): void { 
        this._onChange = fn; 
    }
}