import { Directive, ElementRef, Output, EventEmitter, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {
  //@Output() clickOutside = new EventEmitter();
  @Input('toggleButton') toggleButton; 
  @Input('toggleClass') toggleClass: string; 

  @HostListener('document:click',['$event.target']) onClick(targetelement){
    //console.log(this._elementRef.nativeElement);
    //!this._elementRef.nativeElement.contains(targetelement) ? this.clickOutside.emit() : null

    if (!this.checkParent(targetelement, this._elementRef.nativeElement)) {
      // click NOT on the menu
      if (this.checkParent(targetelement, this.toggleButton)) {
        // click on the link
        if (this._elementRef.nativeElement.classList.contains(this.toggleClass)) {
          this._elementRef.nativeElement.classList.remove(this.toggleClass);
        } else {
          this._elementRef.nativeElement.classList.add(this.toggleClass);
        }
      } else {
        // click both outside link and outside menu, hide menu
        this._elementRef.nativeElement.classList.add(this.toggleClass);
      }
      } 
  }

  constructor(private _elementRef: ElementRef) { }

  checkParent(t, elm) {
	  while(t.parentNode) {
		if( t == elm ) {return true;}
		t = t.parentNode;
	  }
	  return false;
	}

}
