import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpUserEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import {BehaviorSubject} from 'rxjs';
import { Observable,throwError } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import {filter,switchMap,catchError,take,finalize} from 'rxjs/operators'

@Injectable()

export class TokenInterceptorService implements HttpInterceptor{
  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private _authService : AuthService) { }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token }})
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
      return next.handle(this.addToken(req, this._authService.getAccessToken())).pipe(
          catchError(error => {
            if (
                req.url.includes("refresh") ||
                req.url.includes("login")
            ) {
                // We do another check to see if refresh token failed
                // In this case we want to logout user and to redirect it to login page

                if (req.url.includes("refresh")) {
                    this._authService.logOut();
                }

                return throwError(error);
            }
              if (error instanceof HttpErrorResponse) {
                  switch ((<HttpErrorResponse>error).status) {
                      case 403:
                          return this.handle403Error(req, next, error);
                      case 401:
                          return this.handle401Error(req, next, error);
                      default:
                        return throwError(error);
                  }
              } else {
                  return throwError(error);
              }
      }));
  }

    handle401Error(req: HttpRequest<any>, next: HttpHandler, error :HttpErrorResponse) {
        if(this._authService.loggedIn()){
          if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);
 
            return this._authService.refreshToken(this._authService.getRefreshToken()).pipe(
                switchMap((user) => {
                    if (user) {
                        this._authService.persisteToken(user);
                        this.tokenSubject.next(user.access_token);
                        return next.handle(this.addToken(req, user.access_token));
                    }
 
                    // If we don't get a new token, we are in trouble so logout.
                    this._authService.logOut()
                    return throwError(error);
                }),
                catchError(error => {
                    // If there is an exception calling 'refreshToken', bad news so logout.
                    this._authService.logOut()
                    return throwError(error);
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }));
          } else {
              return this.tokenSubject.pipe(
                  filter(token => token != null),
                  take(1),
                  switchMap(token => {
                      return next.handle(this.addToken(req, token));
                  })
              );
          }
        }else{
          this._authService.logOut()
          return throwError(error);
        }
        
    }
 

    handle403Error(req: HttpRequest<any>, next: HttpHandler, error :HttpErrorResponse) {
        this._authService.logOut();
        return throwError(error);
    }
}
