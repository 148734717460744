export class AppConfig{
    id: number;
    year: number;
    start_date: Date;
    end_date: Date;
    cmi_store_key: string;
    email: string;

    constructor(
        _id: number = null,
        _year: number = null,
        _start_date: Date = null,
        _end_date: Date = null,
        _cmi_store_key: string = null,
        _email: string = null
    ){
        this.id = _id;
        this.year = _year;
        this.start_date = _start_date;
        this.end_date = _end_date;
        this.cmi_store_key = _cmi_store_key;
        this.email = _email;
    }
}