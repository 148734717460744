import { Directive, OnInit, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AuthService } from "../auth/auth.service";

@Directive({
    selector: '[appCanAccess]'
})

export class CanAccessDirective implements OnInit{
    @Input('appCanAccess') appCanAccess: {
        requiredPermissions: string[],
        additionalCondition: boolean,
        needAdditionalCondition: boolean
    };

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private _authService: AuthService) {
    }

    ngOnInit(): void {
        if(this.appCanAccess.needAdditionalCondition){
            if (this._authService.checkAuthorization(this.appCanAccess.requiredPermissions) && this.appCanAccess.additionalCondition) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }   
        }else{
            if (this._authService.checkAuthorization(this.appCanAccess.requiredPermissions)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }    
        } 
    }
}