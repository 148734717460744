import { Injectable, Inject } from "@angular/core";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from '../../environments/environment';
import { BehaviorSubject } from "rxjs";

@Injectable()

export class AuthService{
    csrf_token = null;
    redirectUrl: string = null;

    constructor(
        private _http : HttpClient,
        @Inject(SESSION_STORAGE) private _storage: StorageService,
        private _router : Router
    ) { }

    login(user){
        user.grant_type = "password";
        user.scop = "";
        user.email = user.username;


        // return this._http.post<any>(`${environment.authUrl}/token`,user);
        return this._http.post<any>(`${environment.baseUrl}/login`,user);
    }

    loggedIn(){
        return this._storage.get('user') ? true : false;
    }

    getAccessToken(){
        const user = this._storage.get('user');
        return user ? user.access_token : '';
    }

    getRefreshToken(){
        const user = this._storage.get('user');
        return user ? user.refresh_token : '';
    }

    refreshToken(refresh_token){
        return this._http.post<any>(`${environment.baseUrl}/token/refresh`,{
             grant_type : 'refresh_token',
             refresh_token : refresh_token,
             //client_id  : `${environment.client_id}`,
             // client_secret  : `${environment.client_secret}`,
             //scope : '',
        });
    }

    persisteToken(user){
        this._storage.set('user',user);
    }

    logOut(){
        this._storage.remove('user');
        this._router.navigate(['/auth','login']);
    }

    getUserPermissions(){
        const user = this._storage.get('user');
        return user ? user.scopes : [];
    }
    
    checkAuthorization(permissions: string[]){
        return permissions.reduce((state, permission) => {
            return state || this.getUserPermissions().includes(permission);
        },false);
    }

    getUserName(){
        const user = this._storage.get('user');
        return user ? user.user_name : '';
    }

}
