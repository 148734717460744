import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { cloneDeep } from 'lodash';
import { AppConfig } from '../models/AppConfig';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  displayTC: boolean = false;
  displayBP: boolean = false;
  appConfig: AppConfig = null;

  year: number = 2020;
  constructor(
    private _appService: AppService
  ) { }

  ngOnInit() {
    this.appConfig = cloneDeep(this._appService.appConfig);
    this.year = this.appConfig.year;

    this._appService.appConfigRefreshed.subscribe(() =>{
      this.appConfig = cloneDeep(this._appService.appConfig);
      this.year = this.appConfig.year;
    });
  }

}
