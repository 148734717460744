import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StorageServiceModule } from 'ngx-webstorage-service';

import { SharedModule } from './shared/shared.module';
import { NotificationsComponent } from './shared/notifications/notifications.component';
import { NotificationsService } from './shared/notifications/notifications.service';
import { ConfirmationService } from 'primeng/api';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CartService } from './front-end/cart/cart.service';
import { AuthService } from './auth/auth.service';
import { TokenInterceptorService } from './shared/token-interceptor.service';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppService } from './app.service';
// import { Auth2Component } from './auth2/auth2.component';
// import { LoginComponent } from './auth2/login/login.component';
// import { Auth2RoutingModule } from './auth2/auth2-routing.module';



@NgModule({
  declarations: [
    AppComponent,
    NotificationsComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StorageServiceModule,
    NgxSpinnerModule,
    SharedModule,
  ],
  providers: [AppService,NotificationsService,ConfirmationService,CartService,AuthService,{
    provide : HTTP_INTERCEPTORS,
    useClass : TokenInterceptorService,
    multi : true
  },
  {
    provide: RECAPTCHA_SETTINGS,
    useValue: { siteKey: '6Lf0DFIUAAAAALxtFF1K4_eIBndyTHoeB_5sEP82' } as RecaptchaSettings,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
